import React from 'react';
import 'assets/app.scss';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import { Container } from 'semantic-ui-react';
import MainMenu from './MainMenu';

const App: React.FC = () => (
    <BrowserRouter>
        <AppHeader />
        <MainMenu />
        <Container>
            <div className="app">
                <AppRoutes />
            </div>
        </Container>
        <div className="spacer"></div>
        <AppFooter />
    </BrowserRouter>
);

export default App;
