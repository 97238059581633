import axios from 'axios';
import { FeedbackDto, FeedbackResult } from 'types/common';
import config from 'config/config';

class FeedbackService {
    host = config.API_BASE_URL;
    apiPath = '/api/v1/lanfeedback';

    async feedback(feedback: FeedbackDto): Promise<FeedbackResult> {
        const result = await axios.post(`${this.host}${this.apiPath}`, feedback);
        return result.data;
    }
}

export default new FeedbackService();
