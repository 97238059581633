import React, { FC } from 'react';
import { Header, Label } from 'semantic-ui-react';

interface SubtitleProps {
    title: string;
    size?: 'huge' | 'big' | 'normal' | 'small';
    id?: string;
}

const Subtitle: FC<SubtitleProps> = ({ title, size, id }: SubtitleProps) => {
    let asTag = 'h3';

    switch (size) {
        case 'huge':
            asTag = 'h1';
            break;

        case 'big':
            asTag = 'h2';
            break;

        case 'normal':
            asTag = 'h3';
            break;

        case 'small':
            asTag = 'h4';
            break;
    }

    return (
        <Label ribbon style={{ marginBottom: 20 }}>
            <Header as={asTag} style={{ paddingTop: 5, paddingBottom: 2 }} id={id}>
                {title}
            </Header>
        </Label>
    );
};

export default Subtitle;
