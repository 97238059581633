import React, { CSSProperties, FC } from 'react';
import { ErrorMessage, Field, FieldInputProps } from 'formik';
import { Form } from 'semantic-ui-react';

interface FieldCheckboxProps {
    name: string;
    label: string;
    placeholder?: string;
    required?: boolean;
    size?: 'mini' | 'small' | 'large' | 'big' | 'huge' | 'massive';
    style?: CSSProperties;
}

const FieldCheckbox: FC<FieldCheckboxProps> = ({
    name,
    label,
    placeholder,
    required,
    size,
    style
}: FieldCheckboxProps) => {
    return (
        <>
            <Field name={name}>
                {({ field }: { field: FieldInputProps<string> }) => (
                    <Form.Checkbox
                        fitted
                        label={label + (required ? ' *' : '')}
                        placeholder={placeholder || label}
                        checked={field.checked}
                        onChange={field.onChange}
                        id={field.name}
                        onBlur={field.onBlur}
                        style={style}
                        size={size}
                    />
                )}
            </Field>
            <ErrorMessage
                name={name}
                createRoot={(errorMessage: any) => <span className="required">{errorMessage}</span>}
            />
        </>
    );
};

export default FieldCheckbox;
