import React from 'react';
import ReactDOM from 'react-dom/client';
import 'assets/index.scss';
import App from 'components/App';
import * as serviceWorker from './serviceWorker';
import config from 'config/config';
import Axios from 'axios';
import 'semantic-ui-css/semantic.min.css';

// Set axios base
Axios.defaults.baseURL = config.API_BASE_URL;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

serviceWorker.unregister();
