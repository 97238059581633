import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import 'assets/Table.scss';

interface TableProps {
    booked: string[];
    location: string;
    booking: string;
    toggle: (location: string) => void;
    right?: boolean;
    flip?: boolean;
    rowstart?: boolean;
    disabled?: boolean;
}

const Table: FC<TableProps> = (props: TableProps) => {
    const [booked, setBooked] = useState(false);

    useEffect(() => {
        const isBooked = props.booked.includes(props.location);
        setBooked(isBooked);
    }, [props.booked, props.location]);

    const onTableClick = () => {
        if (!props.disabled) {
            if (booked === false) {
                props.toggle(props.location);
            }

            if (props.location === props.booking) {
                props.toggle(props.location);
            }
        }
    };

    const tableClasses = classNames('table', { 'table-upside-down': props.flip });
    const tableNumberClass = classNames('table-normal', 'table-part', { 'table-upside-down': props.flip });
    const buttonClass = classNames(
        'table-button',
        { 'table-booking': props.booking === props.location },
        { 'table-free': !booked && !props.disabled },
        { 'table-booked': booked },
        { 'table-disabled': props.disabled }
    );

    return (
        <div className={tableClasses}>
            <div />
            <div className="table-part table-chair" />
            <div />
            <div>
                <button className={buttonClass} onClick={onTableClick}>
                    <div className={tableNumberClass}>{props.location}</div>
                </button>
            </div>
        </div>
    );
};

export default Table;
