import React from 'react';
import { Link } from 'react-router-dom';

const AppFooter: React.FC = () => {
    return (
        <footer className="app-footer">
            <div>
                &copy; {new Date().getFullYear()} - Sponsored by{' '}
                <a
                    style={{ color: '#13768f', textDecoration: 'none' }}
                    href="https://www.datacodex.fi"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    DataCodex Oy
                </a>
                <span style={{ marginLeft: 10, marginRight: 10 }}>|</span>
                <Link to="/registryPolicy">Rekisteriseloste</Link>
            </div>
        </footer>
    );
};

export default AppFooter;
