import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './Dashboard';
import PageNotFound from './PageNotFound';
import InfoView from './InfoView';
import BookingView from './booking/BookingView';
import RegistrationView from './registration/RegistrationView';
import IntraView from './IntraView';
import FeedbackView from './FeedbackView';
import RegistryPolicy from './RegistryPolicy';
import TodoView from './TodoView';

const AppRoutes: React.FC = () => (
    <Routes>
        <Route path="/info" element={<InfoView />} />
        <Route path="/registration" element={<RegistrationView />} />
        <Route path="/ilmo" element={<RegistrationView />} />
        <Route path="/booking" element={<BookingView />} />
        <Route path="/intra" element={<IntraView />} />
        <Route path="/feedback" element={<FeedbackView />} />
        <Route path="/registryPolicy" element={<RegistryPolicy />} />
        <Route path="/todo" element={<TodoView />} />

        <Route path="/" element={<Dashboard />} />
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
);

export default AppRoutes;
