import React, { FC, useEffect, useState } from 'react';
import { Button, Grid, Input } from 'semantic-ui-react';
import tableService from 'services/tableService';

interface AuthBarProps {
    booking: string;
    setAuth: (email: string, code: string) => void;
    setBooking: (location: string) => void;
}

const AuthBar: FC<AuthBarProps> = (props: AuthBarProps) => {
    const [msg, setMsg] = useState('');
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');

    useEffect(() => {
        if (props.booking === '-') {
            setMsg('Et ole vielä varannut pöytää');
        } else if (props.booking) {
            setMsg('Varauksesi on ' + props.booking);
        }
    }, [props.booking]);

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        props.setAuth(email, code);
    };

    const handleChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCode(event.target.value);
        props.setAuth(email, code);
    };

    const onClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (email === '' || code === '') {
            setError('Sähköposti ja koodi on pakollisia tietoja');
            return;
        }

        tableService.myBooking(email, code).then(res => {
            if (res.data) {
                if (res.data === '-') {
                    setMsg('Et ole vielä varannut pöytää');
                } else {
                    setMsg('Varauksesi on ' + res.data);
                }
            } else {
                setError('Virheellinen sähköposti tai koodi');
            }

            props.setBooking(res.data);
        });
    };

    return (
        <Grid style={{ marginTop: 10, marginBottom: 10 }}>
            <Grid.Row>
                <Grid.Column width={3}>
                    <Input
                        placeholder="S&auml;hk&ouml;posti"
                        id="email"
                        value={email}
                        onChange={handleChangeEmail}
                        onBlur={handleChangeEmail}
                        style={{ maxHeight: 40 }}
                    />
                </Grid.Column>
                <Grid.Column width={3}>
                    <Input
                        placeholder="Koodi"
                        id="code"
                        value={code}
                        onChange={handleChangeCode}
                        onBlur={handleChangeCode}
                        style={{ maxHeight: 40 }}
                    />
                </Grid.Column>
                <Grid.Column width={2}>
                    <Button variant="contained" primary onClick={onClickButton}>
                        Tarkista
                    </Button>
                </Grid.Column>
                <Grid.Column width={3}>
                    {msg ? <span style={{ color: '#6478df' }}>{msg}</span> : null}
                    {error ? <span style={{ color: '#aa2211' }}>{error}</span> : null}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default AuthBar;
