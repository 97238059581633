import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Grid, Header } from 'semantic-ui-react';

interface DashboardButtonProps {
    title: string;
    url: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon?: any;
    infoText?: string;
}

const DashboardButton: React.FC<DashboardButtonProps> = ({ title, url, icon, infoText }: DashboardButtonProps) => {
    const navigate = useNavigate();
    const Icon = icon;

    return (
        <Card
            style={{ color: 'grey' }}
            onClick={() => {
                navigate(url);
            }}
        >
            <Card.Content>
                <Card.Header>
                    <Grid container columns={2}>
                        <Grid.Column width={2}>{!!icon && <Icon fontSize="large" color="primary" />}</Grid.Column>
                        <Grid.Column width={13}>
                            <Header as="h2">{title}</Header>
                        </Grid.Column>
                    </Grid>
                </Card.Header>
                {!!infoText && (
                    <Card.Content>
                        <Grid container columns={2}>
                            <Grid.Column width={2} />
                            <Grid.Column width={13} style={{ minHeight: 70 }}>
                                {infoText}
                            </Grid.Column>
                        </Grid>
                    </Card.Content>
                )}
            </Card.Content>
        </Card>
    );
};

export default DashboardButton;
