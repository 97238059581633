import React, { FC } from 'react';
import Subtitle from 'components/Subtitle';

const SleepingInfo: FC = () => {
    return (
        <>
            <Subtitle title="Tapahtuma on 48h! Varaudu yövarusteilla!" />
            <p>
                Meillä on useita tiloja yöpymiselle, tytöille ja pojille erikseen. Jotain patjaa tai pehmeähköä penkkiä
                löytyy, mutta halutessasi voit tuoda oman patjan/ilmapatjan yms. jos haluat ja makuupussi/peitto yms.
                yöpymistarvikkeet.
            </p>
            <p>Pari suihkuakin on käytettävissä.</p>
        </>
    );
};

export default SleepingInfo;
