import React, { FC } from 'react';
import { Grid } from 'semantic-ui-react';
import Subtitle from 'components/Subtitle';
import FieldCheckbox from './FieldCheckbox';

const AttendanceDays: FC = () => {
    return (
        <>
            <Subtitle title="Osallistumispäivät" />
            <Grid columns={2} stretched stackable>
                <Grid.Column width="16">
                    <FieldCheckbox name="osallistumisToPe" label="torstai - perjantai" />
                </Grid.Column>
                <Grid.Column width="16">
                    <FieldCheckbox name="osallistumisPeLa" label="perjantai - lauantai" />
                </Grid.Column>
            </Grid>
        </>
    );
};

export default AttendanceDays;
