import React from 'react';
import tila from 'assets/lanitila.jpg';
import ruokapoyta from 'assets/ruokapoyta.jpg';
import paidat from 'assets/staff-paidat.jpg';
import { Container, Image, Segment } from 'semantic-ui-react';
import Subtitle from './Subtitle';
import config from 'config/config';

const InfoView: React.FC = () => {
    return (
        <Container text>
            <Image src={tila} size="big" alt="Lani tila" centered rounded />
            <Segment color="orange">
                <Subtitle size="big" title="Tietoja Laneista" />
                <fieldset>
                    <legend>Sisällys</legend>
                    <ul>
                        <li>
                            <a href="#taustaa">Taustaa</a>
                        </li>
                        <li>
                            <a href="#tapahtumapaikka">Tapahtumapaikka</a>
                        </li>
                        <li>
                            <a href="#ikarajat">Ikärajat</a>
                        </li>
                        <li>
                            <a href="#ilmoittautuminen">Ilmoittautuminen</a>
                        </li>
                        <li>
                            <a href="#maksut">Maksut</a>
                        </li>
                        <li>
                            <a href="#mitamukaan">Mitä tarvitaan mukaan?</a>
                        </li>
                        <li>
                            <a href="#poytatila">Pöytätila</a>
                        </li>
                        <li>
                            <a href="#saankoottaamukaan">Saanko ottaa mukaan?</a>
                        </li>
                        <li>
                            <a href="#ruoka">Ruoka</a>
                        </li>
                        <li>
                            <a href="#ohjelma">Ohjelma</a>
                        </li>
                        <li>
                            <a href="#ohjeisohjelmaa">Oheisohjelmaa</a>
                        </li>
                        <li>
                            <a href="#majoittuminen">Majoittuminen</a>
                        </li>
                        <li>
                            <a href="#turvallisuus">Turvallisuus</a>
                        </li>
                        <li>
                            <a href="#teknisiaongelmia">Teknisiä ongelmia tai kysyttävää tapahtuman aikana?</a>
                        </li>
                        <li>
                            <a href="#yhteystiedot">Yhteystiedot</a>
                        </li>
                    </ul>
                </fieldset>
            </Segment>
            <Segment color="orange">
                <Subtitle id="taustaa" title="Taustaa" />
                <p>
                    Ensimmäiset lanit järjestettiin seurakunnassamme 30.9.2016. Siitä lähtien niitä on pidetty kaksi -
                    neljä kertaa vuodessa.
                </p>
                <p>
                    Tarkoitus on kerätä yhteen pelaamisesta kiinnostuneita nuoria ja viettää hyvää aikaa yhdessä
                    pelaamisen merkeissä. Ohjelmassa on aina myös aloitushetki, jossa on infoasiat ja lyhyt opetus
                    Raamatusta. Alkuhetki kestää yleensä noin puoli tuntia ja on ainoa ns. pakollinen ohjelmanumero.
                    Muuten turnauksiin, tapahtumiin ja muuhun ohjelmaan osallistuminen on vapaaehtoista. Nukkuminenkin
                    on vapaaehtoista, tilat ja mahdollisuus siihen on kuitenkin aina olemassa.
                </p>
                <p>
                    Tapahtuma on K13 ja siten päihteetön. Ikärajasta voidaan vähän joustaa jos esim. sisaruksia on
                    mukana ja huoltajalta saadaan lupa.
                </p>
            </Segment>
            <Segment color="orange">
                <Subtitle id="tapahtumapaikka" title="Tapahtumapaikka" />
                <p>
                    Tapahtumapaikka on Hyvinkään helluntaiseurakunnan kirkko, osoitteessa Palo-ojantie 1, 05810
                    Hyvinkää.
                </p>
            </Segment>
            <Segment color="orange">
                <Subtitle id="ikarajat" title="Ikärajat" />
                <p>
                    Tapahtuma on K13 ja siten päihteetön. Ikärajasta voidaan vähän joustaa jos esim. sisaruksia on
                    mukana ja huoltajalta saadaan lupa.
                </p>
                <p>
                    Tapahtumassa pelattavien pelin osalta noudatamme{' '}
                    <a href="https://pegi.info/fi" target="_blank" rel="noreferrer">
                        PEGI
                    </a>{' '}
                    suosituksia, joita tulkitsemme samoin kuin Tampereen Ev.Lut. seurakunnan{' '}
                    <a
                        href="https://tampereenseurakunnat.fi/sivustot/bittileiri/tietoa_leirista/pelien_ikarajat"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Bittileirillä
                    </a>{' '}
                    on ohjeistettu.
                </p>
                <p>
                    Energiajuomien käyttöä suosittelemme maltilla ja alle 15-vuotiaille emme niitä suosittele ollenkaan.
                </p>
            </Segment>
            <Segment color="orange">
                <Subtitle id="ilmoittautuminen" title="Ilmoittautuminen" />
                <p>
                    Laneille tulee ilmoittautua etukäteen ilmoittautumislomakkeella. Näin tiedämme varata pöytätilaa
                    kaikille. Mukaan mahtuu noin 80 ensin ilmoittautunutta. Ilmoittautumisen jälkeen noin 24h sisällä
                    lähetetään ilmoittautuneelle sähköpostilla linkki ja tunnukset pöytävaraussovellukseen.
                </p>
                <p>
                    Pöytäpaikan voi varata etukäteen, jos haluaa varmistaa vaikka kaverinsa kanssa vierekkäiset paikat.
                    Pöytävaraus ei ole pakollinen, kaikille löytyy varmasti paikka. Pöytävaraustilanne näkyy erillisessä
                    sovelluksessa.
                </p>
                <p>
                    Alle 15-vuotiailta tarvitsemme huoltajan tiedot (ja suostumuksen). Suostumukseksi riittää toki se,
                    jos vanhempi kyyditsee nuoren tapahtumapaikalle.
                </p>
            </Segment>
            <Segment color="orange">
                <Subtitle id="maksut" title="Maksut" />
                <p>
                    Tilaisuus on maksuton. Ruoka on maksullinen, jos sen haluaa ottaa. Katso tarkemmin alempaa kohdasta
                    Ruoka. Maksaa voi käteisellä tai korttimaksuna.
                </p>
            </Segment>
            <Segment color="orange">
                <Subtitle id="mitamukaan" title="Mitä tarvitaan mukaan?" />
                <ul>
                    <li>tietokone tai konsoli ja tarvittavat lisälaitteet ja johdot</li>
                    <li>sähköjatkojohto, meiltä saa yhden pistorasiapaikan</li>
                    <li>nettijohto, konepaikoilta löytyy n. 2 metrin päästä kytkin, josta saa nettiyhteyden</li>
                    <li>
                        yöpymisvarusteet: patja, makuupussi/peitto jne. Joitain patjoja ja vuodesohvia löytyy talostakin
                    </li>
                    <li>ruokamaksu, mikäli varasit ruuan. Maksaa voi käteisellä tai korttimaksuna.</li>
                </ul>
            </Segment>
            <Segment color="orange">
                <Subtitle id="poytatila" title="Pöytätila" />
                <p>
                    Jokaiselle pelaajalle on varattu pöytäpaikka, jonka koko on 85 x 75 cm ja vieressä on pehmustettu
                    tuoli! Yksi pistorasia ja yksi nettipistoke per pöytä. Tuo oma jatkojohto ja oma nettipiuha.
                </p>
            </Segment>
            <Segment color="orange">
                <Subtitle id="saankoottaamukaan" title="Saanko ottaa mukaan?" />
                <ul>
                    <li>
                        omat ruuat, limua, sipsejä, karkkia? Saa tuoda, energiajuomia emme suosittele alle 15-vuotiaille
                    </li>
                    <li>
                        pelikoneen x, lisänäytön tai kolme lisänäyttöä? Saa tuoda, jos saat ne/sen mahtumaan
                        pöytäpaikallesi
                    </li>
                    <li>kaiuttimet? Älä tuo omia kaiuttimia, ota mieluummin kuulokkeet ja kuuntele musiikkia niillä</li>
                    <li>vanhemmat? Vanhemmat saa tulla tutustumaan ja katselemaan tapahtumaa</li>
                </ul>
            </Segment>
            <Segment color="orange">
                <Subtitle id="ruoka" title="Ruoka" />
                <p>
                    Ruokaa on yleensä tarjolla, ilmoittautumislomakkeesta näkee kulloisenkin tapahtuman tilanteen
                    varmuudella. Ruoka tulee varata ilmoittautuessa.
                </p>
                <p>
                    Ruokamaksu on laneilla {config.FOOD_PRICE} €. Se kattaa ohjelmassa näkyvät ruokailut,
                    lukuunottamatta jälkimmäisen illan pizza/kebab tilauksia. Omia ruokia saa ottaa mukaan, keittiötilat
                    löytyy jos haluaa vaikka mikrossa lämmittää ruokia ja jääkaappitilaa on ruuan säilytykseen.
                    Maksuvälineinä kortti, lähimaksu tai MobilePay.
                </p>
                <p>Muistathan ilmoittautuessa kirjata myös mahdolliset ruokavaliorajoitukset ja allergiat.</p>
                <p>
                    Iltaruokana on usein ollut keittiöväen itse paikalla tekemää pitsaa ja salaattia, mehu/tee/kahvit
                    juomana. Aamupala leipä ja leikkelee -tyylillä sekä juomat. Lounaalla on ollut esim. ranskalaisia ja
                    nakkeja sekä salaattia.
                </p>
                <p>
                    Jälkimmäisen päivän iltana on tehty yhteistilaus kahteen ravintolaan pizza ja kebab -annoksia. Nämä
                    tilaukset maksetaan itse erikseen. Tilaukset haetaan lanitapahtumapaikalle AsLAN Pizzataxilla.
                </p>
            </Segment>
            <Image src={ruokapoyta} size="large" alt="Ruokapöytä" centered rounded />
            <Segment color="orange">
                <Subtitle id="ohjelma" title="Ohjelma" />
                <p>
                    Ohjelma hieman vaihtelee lanikertojen välillä ja riippuen onko 24h vai 48h lanit kyseessä. Mutta
                    tässä karkea kuvaus ohjelmasisällöstä 48h laneilta:
                </p>
                <p>Aloituspäivä:</p>
                <p>
                    klo 16 ovet aukeaa ja koneita voi tulla kasaamaan
                    <br />
                    klo 19 aloitustilaisuus (ainoa pakollinen ohjelmanumero)
                    <br />
                    klo 20 turnaus #1
                    <br />
                    klo 21-22 ruoka
                    <br />
                    klo 23 turnaus #2
                </p>
                <p>Seuraava päivä:</p>
                <p>
                    klo 02 hiljaisuus alkaa, mahdollisuus nukkumiseen toki aikaisemminkin
                    <br />
                    klo 09 hiljaisuus päättyy, nukkua saa pidempäänkin
                    <br />
                    klo 10 aamupala
                    <br />
                    klo 11 ulkoilua/liikkuvaa aktiviteettia
                    <br />
                    klo 13 lounas
                    <br />
                    klo 16 opetustilaisuus (vapaaehtoinen)
                    <br />
                    klo 17 välipala
                    <br />
                    klo 18 turnaus #3
                    <br />
                    klo 21 ruoka, AsLAN Pizzataxi
                    <br />
                    klo 23 turnaus #4
                </p>
                <p>Viimeinen päivä:</p>
                <p>
                    klo 02 hiljaisuus alkaa, mahdollisuus nukkumiseen toki aikaisemminkin
                    <br />
                    klo 09 hiljaisuus päättyy, nukkua saa pidempäänkin
                    <br />
                    klo 10 aamupala
                    <br />
                    klo ~ iltapäivällä alkaa tilojen purkaminen, ei ole kiire pois, purkaminen kestää yleensä ainakin
                    klo 18 asti
                </p>
            </Segment>
            <Segment color="orange">
                <Subtitle id="ohjeisohjelmaa" title="Oheisohjelmaa" />
                <p>Meillä on lanitiloissa kaikkien vapaasti käytettävissä</p>
                <ul>
                    <li>mm. vanha Commodore 64, jolla voi käydä pelaamassa ja kokea tietotekniikan historiaa</li>
                    <li>fyysistä aktiviteettia lisäämään pöytäjalkapallo, minibiljardi ja pingispöytä</li>
                    <li>salin sivuosassa on myös yksi PS3-konsoli, jolla voi pelata</li>
                </ul>
            </Segment>
            <Segment color="orange">
                <Subtitle id="majoittuminen" title="Majoittuminen" />
                <p>
                    Majoitustiloja löytyy useita ja osa niistä on etäällä lanitilasta, joten niissä pystyy hyvin
                    nukkumaan. Nukkumaan voi mennä oman aikataulun mukaan, yöllä klo 3 - 9 on hiljaisuus, jolloin
                    vältetään melua. Laneilla on yleensä melko rauhallista ja nukkumaan pystyy hyvin klo 24 - 10 välin,
                    jos haluaa.
                </p>
                <p>
                    Nukkumatilat on erikseen pojille ja tytöille. Niitä on mm. takkahuone, kaksi pyhäkouluhuonetta,
                    lasten leikkihuone ja pääsalissa voi myös nukkua. Tiloista löytyy muutama vuodesohva ja jokusia
                    patjoja, mutta varminta on jos tuo oman (ilma)patjan mukanaan. Mikäli omaa patjaa ei ole, kannattaa
                    paikalle tullessa tästä kertoa, niin järjestetään sellainen. Pääsalin penkit on pehmustettuja.
                    Niillä on moni myös yönsä nukkunut.
                </p>
                <p>
                    Petivaatteita ei ole tarjota, joten makuupussi tai peitto/tyyny pitää tuoda mukanaan. Suihkukin
                    löytyy, jos haluaa aamulla peseytyä ja tietysti useita wc-tiloja.
                </p>
            </Segment>
            <Segment color="orange">
                <Subtitle id="turvallisuus" title="Turvallisuus" />
                <p>
                    Kaikki lanitilat, tietokoneet ja nukkumistilat on tapahtuman ajan valvottuja. Paikalla on useita
                    aikuisia järjestäjiä ja vähintään kaksi heistä valvoo läpi koko yön.
                </p>
                <p>Ulko-ovet ovat lukossa klo 24 - 8 välisen ajan.</p>
                <p>
                    Mahdollisissa vahinkotapauksissa ilmoitetaan vanhemmille välittömästi. Onneksi näitä ei ole vielä
                    kertaakaan tapahtunut.
                </p>
                <p>
                    Yövahtien puhelinnumero on selkeästi näkyvillä ja eteisen infopisteestä sen voi myös vanhemmat
                    halutessaan ottaa muistiin.
                </p>
            </Segment>
            <Segment color="orange">
                <Subtitle id="teknisiaongelmia" title="Teknisiä ongelmia tai kysyttävää tapahtuman aikana?" />
                <p>
                    Tapahtuman järjestäjillä on kuvan mukaiset paidat päällä. Heiltä voi kysyä mitä vaan kysyttävää
                    tulee mieleen. Olipa kysymys teknisistä koneiden ongelmista tai turnauksista tai järjestelyistä tai
                    nukkumapaikasta tai haluaa muuten vaan jutella.
                </p>
            </Segment>
            <Image src={paidat} size="large" alt="Staff-paidat" centered rounded />
            <Segment color="orange">
                <Subtitle id="yhteystiedot" title="Yhteystiedot" />

                <p>Tapahtuman järjestää Hyvinkään Helluntaiseurakunta.</p>
                <p>
                    Tapahtuman koordinaattori
                    <br />
                    Sampo Antila
                    <br />
                    045 1114505
                    <br />
                    sampo.antila (at) hyvinkaanhelluntaisrk.fi
                </p>
            </Segment>
        </Container>
    );
};

export default InfoView;
