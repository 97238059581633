import React, { FC } from 'react';
import Table from './Table';

interface TableGroupProps {
    name: string;
    booked: string[];
    booking: string;
    toggle: (location: string) => void;
    extra?: boolean;
    disabled?: boolean;
}

const TableGroup: FC<TableGroupProps> = (props: TableGroupProps) => {
    const labelClass = 'table-group-label';
    let sizeClass = ' table-group-normal';

    let tables = [1, 2, 3, 4];
    let flipTables = [5, 6, 7, 8];

    if (props.extra) {
        sizeClass = ' table-group-extra';
        tables = [1, 2, 3, 4, 5, 6, 7];
        flipTables = [8, 9, 10, 11, 12, 13, 14];
    }

    return (
        <div className={'table-group' + sizeClass}>
            <div className={'table-group-content'}>
                <div className={labelClass}>{props.name}</div>
                {tables.map(item => (
                    <Table
                        key={item}
                        location={props.name + item}
                        booked={props.booked}
                        booking={props.booking}
                        toggle={props.toggle}
                        disabled={props.disabled}
                    />
                ))}
                {flipTables.map(item => (
                    <Table
                        key={item}
                        flip
                        location={props.name + item}
                        booked={props.booked}
                        booking={props.booking}
                        toggle={props.toggle}
                        disabled={props.disabled}
                    />
                ))}
            </div>
        </div>
    );
};

export default TableGroup;
