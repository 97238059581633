import React, { FC, useEffect, useState } from 'react';
import TableGroup from './TableGroup';
import AuthBar from './AuthBar';
import tableService from 'services/tableService';
import registrationService from 'services/registrationService';
import 'assets/app.scss';
import { Grid, Message } from 'semantic-ui-react';
import config from 'config/config';

const Content: FC = () => {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [booked, setBooked] = useState([]);
    const [booking, setBooking] = useState('');
    const [loading, setLoading] = useState(true);
    const [registered, setRegistered] = useState<number>();

    useEffect(() => {
        loadBooked();
        loadRegistered();
    }, []);

    const setBookingLocation = (location: string) => {
        setBooking(location);
    };

    const loadBooked = () => {
        tableService
            .allBooked()
            .then(res => {
                setBooked(res.data);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            });
    };

    const loadRegistered = () => {
        registrationService
            .count()
            .then(res => {
                setRegistered(res);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const setAuth = (authEmail: string, authCode: string) => {
        setEmail(authEmail);
        setCode(authCode);
    };

    const toggle = (location: string) => {
        if (booking === location) {
            tableService
                .free(email, code)
                .then(() => {
                    setBookingLocation('-');
                    loadBooked();
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            tableService
                .book(email, code, location)
                .then(() => {
                    setBookingLocation(location);
                    loadBooked();
                })
                .catch(err => {
                    console.log(err);
                });
        }
    };

    const noteForNoTables = (freeAmount: number) => (
        <>
            {freeAmount === 0 && (
                <Message color="blue">
                    <Message.Header>HUOM! Ilmoittautuneita on enemmän kuin tässä näkyviä pöytäpaikkoja.</Message.Header>
                    <Message.Content>
                        Järjestämme kyllä kaikille pöytäpaikan, vaikka nämä paikat tuleevat täyteen.
                    </Message.Content>
                </Message>
            )}
        </>
    );

    const total = 48;
    const reserved = booked.length;
    const free = total - reserved;

    return (
        <div style={{ color: 'whitesmoke' }}>
            {config.REGISTRATION_OPEN === 'true' && (
                <>
                    {loading ? (
                        <Message color="blue">(odota hetki ladataan tietoja...)</Message>
                    ) : (
                        <div>
                            {noteForNoTables(free)}
                            <div style={{ marginTop: 10 }}>
                                <span style={{ marginBottom: 10 }}>
                                    Kirjoita sähköpostisi ja saamasi koodi, sen jälkeen voit varata pöydän vapaista
                                    (vihreistä).
                                    <br />
                                    Varauksen voi vaihtaa klikkaamalla toista pöytää ja poistaa klikkaamalla varattua
                                    pöytää.
                                    <br />
                                    Voit varata vain yhden pöydän.
                                </span>
                                <AuthBar setBooking={setBooking} setAuth={setAuth} booking={booking} />
                                <div style={{ marginBottom: 15, color: '#c6c6c6' }}>
                                    Vapaana paikkoja:{' '}
                                    <span>
                                        {free}/{total} (varattuja: {reserved})
                                    </span>
                                </div>
                                <div style={{ marginBottom: 15, color: '#a6a6a6' }}>
                                    Ilmoittautuneita: <span>{registered}</span>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
            <div className="table-clearfix">
                <h4>Värikoodit</h4>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <div className="table-legend table-free"></div>Vapaa
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <div className="table-legend table-booking"></div>Sinun pöytäsi
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <div className="table-legend table-booked"></div>Varattu
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <div className="table-legend table-disabled"></div>Ei käytössä
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
            <div className="table-clearfix">
                <div className="table-layout">
                    <div style={{ float: 'left', marginLeft: 40 }}>
                        <TableGroup name="A" booked={booked} booking={booking} toggle={toggle} />
                        <TableGroup name="B" booked={booked} booking={booking} toggle={toggle} />
                        <br />
                        <TableGroup name="C" booked={booked} booking={booking} toggle={toggle} />
                        <TableGroup name="D" booked={booked} booking={booking} toggle={toggle} />
                        <br />
                        <TableGroup name="E" booked={booked} booking={booking} toggle={toggle} />
                        <TableGroup name="F" booked={booked} booking={booking} toggle={toggle} />
                    </div>
                    <div style={{ paddingLeft: 100, float: 'left', marginBottom: 60 }}>
                        <TableGroup name="G" booked={booked} booking={booking} toggle={toggle} disabled />
                        <TableGroup name="H" booked={booked} booking={booking} toggle={toggle} disabled />
                    </div>
                    <div className="table-food-table">Ruokapöytä</div>
                </div>
            </div>
        </div>
    );
};

export default Content;
