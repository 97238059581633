import React from 'react';
import DashboardButton from './DashboardButton';
import { Grid, Header, Icon, Image } from 'semantic-ui-react';
import InfoBanner from './registration/InfoBanner';
import tila from 'assets/lanitila2.jpg';
import config from 'config/config';

const Info = () => <Icon name="info circle" />;
const Table = () => <Icon name="table" />;
const Intra = () => <Icon name="gem" />;
const Point = () => <Icon name="angle double right" />;

const Dashboard: React.FC = () => {
    return (
        <>
            <Header as="h2" content="Welkome" textAlign="center" className="subtitle" />
            <Image src={tila} size="big" alt="Lani tila" centered rounded />
            <Grid centered columns={2} stackable>
                <Grid.Row centered columns={4}>
                    <Grid.Column width={10} style={{ marginTop: 20 }}>
                        {config.REGISTRATION_OPEN === 'true' && <InfoBanner />}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered columns={4}>
                    <Grid.Column>
                        <DashboardButton
                            title="Infoa laneista"
                            infoText="perustietoa esim. vanhemmille"
                            url="/info"
                            icon={Info}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <DashboardButton
                            title="Ilmoittautuminen"
                            infoText="Ilmoittaudu laneille"
                            url="/registration"
                            icon={Point}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered columns={4}>
                    <Grid.Column>
                        <DashboardButton
                            title="Pöytävaraus"
                            infoText="Tarkista varautilanne tai vaihda varaustasi"
                            url="/booking"
                            icon={Table}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <DashboardButton
                            title="Intra"
                            infoText="Lanien aikaan materiaalipankki"
                            url="/intra"
                            icon={Intra}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
};

export default Dashboard;
