declare global {
    interface Config {
        API_BASE_URL: string;
        STAGE: string;
        REGISTRATION_OPEN: string;
        EVENT_TIME: string;
        COUNTDOWNCLOCK: string;
        FOOD_PRICE: string;
    }
}

const dev: Config = {
    API_BASE_URL: 'http://localhost:4000',
    STAGE: process.env.REACT_APP_STAGE ?? 'test',
    REGISTRATION_OPEN: 'true',
    EVENT_TIME: '17.-19.10.2024',
    COUNTDOWNCLOCK: '2024-10-17',
    FOOD_PRICE: '15'
};

const prod: Config = {
    API_BASE_URL: process.env.REACT_APP_API_URL ?? 'https://lanbooking-api.azurewebsites.net',
    STAGE: process.env.REACT_APP_STAGE ?? 'production',
    REGISTRATION_OPEN: process.env.REACT_APP_REGISTRATION_OPEN ?? 'false',
    EVENT_TIME: process.env.REACT_APP_EVENT_TIME ?? '',
    COUNTDOWNCLOCK: process.env.REACT_APP_COUNTDOWNCLOCK ?? '2024-10-17',
    FOOD_PRICE: process.env.REACT_APP_FOOD_PRICE ?? '15'
};

const config = process.env.NODE_ENV === 'development' ? dev : prod;

console.log(config);

export default {
    ...config
};
