import axios from 'axios';
import { RegistrationDto, RegistrationResult } from 'types/common';
import config from 'config/config';

class RegistrationService {
    host = config.API_BASE_URL;
    apiPath = '/api/v1/lanregistration';

    async register(registration: RegistrationDto): Promise<RegistrationResult> {
        const result = await axios.post(`${this.host}${this.apiPath}`, registration);
        return result.data;
    }

    async count(): Promise<number> {
        const result = await axios.get(`${this.host}${this.apiPath}/registeredCount`);
        return result.data;
    }
}

export default new RegistrationService();
