import axios from 'axios';
import { TodoDto, TodoResult } from 'types/common';
import config from 'config/config';

class TodoService {
    host = config.API_BASE_URL;
    apiPath = '/api/v1/lantodo';

    async getTodos(): Promise<TodoDto[]> {
        const result = await axios.get(`${this.host}${this.apiPath}`);
        return result.data;
    }

    async addTodo(todo: TodoDto): Promise<TodoResult> {
        const result = await axios.post(`${this.host}${this.apiPath}`, todo);
        return result.data;
    }
}

export default new TodoService();
