import React, { FC } from 'react';
import InfoBanner from './InfoBanner';
import Subtitle from 'components/Subtitle';
import { Label } from 'semantic-ui-react';
import config from 'config/config';

const style = {
    infolabel: { marginBottom: 20 },
    label: { width: 100 }
};

interface InfoLabelProps {
    label: string;
    message: string;
}

const InfoLabel: FC<InfoLabelProps> = ({ label, message }: InfoLabelProps) => {
    return (
        <>
            <div style={style.infolabel}>
                <Label ribbon style={style.label}>
                    {label}
                </Label>
                {message}
            </div>
        </>
    );
};

const BasicInfo: FC = () => {
    return (
        <>
            <InfoBanner />
            <Subtitle title="Perustietoa" />
            <InfoLabel label="Ajankohta" message={config.EVENT_TIME} />
            <InfoLabel label="Osoite" message="Palo-ojantie 1, 05810 Hyvinkää" />
            <InfoLabel label="Lisätietoja" message="045 1114505 / sampo.antila@hyvinkaanhelluntaisrk.fi" />
            <InfoLabel label="Järjestäjä" message="Hyvinkään helluntaiseurakunta" />

            <p>Ovet aukeaa aloituspäivänä viimeistään klo 17. Varsinainen ohjelma alkaa klo 19.</p>
            <p>
                Viimeisenä päivänä lopetellaan iltapäivällä, mutta ei ole kiirettä. Ovet on auki vähintään klo 18 asti.
            </p>
            <p>Tapahtuma on K13 ja päihteetön.</p>
            <p>Sisäänpääsy on ilmainen, vain ruoka maksaa, jos sen haluaa ostaa.</p>
            <p>
                Tiedot kerätään vain tapahtuman valmistelua varten. Tiedot poistetaan kun tapahtuma on ohi. Vain
                sähköpostiosoitteet säilytetään seuraaviin laneihin kutsumista varten.
            </p>
            <p>
                Tapahtumassa pelattavien pelin osalta noudatamme{' '}
                <a href="https://pegi.info/fi" target="_blank" rel="noreferrer">
                    PEGI
                </a>{' '}
                suosituksia, joita tulkitsemme samoin kuin Tampereen Ev.Lut. seurakunnan{' '}
                <a
                    href="https://tampereenseurakunnat.fi/sivustot/bittileiri/tietoa_leirista/pelien_ikarajat"
                    target="_blank"
                    rel="noreferrer"
                >
                    Bittileirillä
                </a>{' '}
                on ohjeistettu.
            </p>
            <p>Energiajuomien käyttöä suosittelemme maltilla ja alle 15-vuotiaille emme niitä suosittele ollenkaan.</p>
        </>
    );
};

export default BasicInfo;
