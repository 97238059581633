import React, { useCallback, useEffect, useState } from 'react';
import {
    Button,
    Container,
    Grid,
    Header,
    List,
    ListContent,
    ListDescription,
    ListHeader,
    ListIcon,
    ListItem,
    Message,
    Rating,
    Segment
} from 'semantic-ui-react';
import { TodoDto } from 'types/common';
import todoService from 'services/todoService';
import { Form, Formik } from 'formik';
import FieldText from './registration/FieldText';
import FieldCheckbox from './registration/FieldCheckbox';

const TodoView: React.FC = () => {
    const emptyMessage = { title: '', success: false };
    const [showMessage, setShowMessage] = useState(emptyMessage);
    const [todos, setTodos] = useState<TodoDto[]>();
    const [selectedTodo, setSelectedTodo] = useState<TodoDto>();
    const initialValues: TodoDto = {
        task: '',
        person: '',
        doing: 0,
        done: 0
    };

    const loadTodos = useCallback(() => {
        todoService
            .getTodos()
            .then(data => {
                console.log(data);
                setTodos(data);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        loadTodos();
    }, [selectedTodo]);

    const storeTodo = useCallback(async (values: TodoDto) => {
        try {
            const res = await todoService.addTodo(values);
            if (res.success) {
                setShowMessage({
                    title: 'Kiitos',
                    success: true
                });
                setTimeout(() => {
                    loadTodos();
                    setShowMessage(emptyMessage);
                }, 2000);
            } else {
                setShowMessage({
                    title: 'Tehtävän tallennus epäonnistui!',
                    success: false
                });
            }
        } catch (err: any) {
            setShowMessage({
                title: 'Tehtävän tallennus epäonnistui!',
                success: false
            });
        }
    }, []);

    return (
        <Container text>
            <Header as="h2">AsLAN Rakentamis-Todo</Header>

            <Segment>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        {!todos && <Header as="h4">Ei tehtäviä</Header>}
                        {todos && <Header as="h4">Tehtävät</Header>}
                        <List>
                            {todos
                                ?.filter(todo => todo.done == 0)
                                .map((todo, index) => (
                                    <ListItem key={index}>
                                        <ListIcon
                                            name={todo.done ? 'checkmark' : 'tasks'}
                                            size="large"
                                            verticalAlign="middle"
                                        />
                                        <ListContent>
                                            <ListDescription
                                                as="a"
                                                content={todo.task}
                                                className={todo.done ? 'task_done' : 'task'}
                                                onClick={() => setSelectedTodo(todos.find(item => (item.id = todo.id)))}
                                            ></ListDescription>
                                        </ListContent>
                                    </ListItem>
                                ))}
                        </List>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1} className="newTaskBtn">
                    <Grid.Column>
                        {todos && <Header as="h4">Valmiit tehtävät</Header>}
                        <List>
                            {todos
                                ?.filter(todo => todo.done == 1)
                                .map((todo, index) => (
                                    <ListItem key={index}>
                                        {todo.done && <ListIcon name="checkmark" size="large" verticalAlign="middle" />}
                                        <ListContent>
                                            <ListDescription
                                                as="a"
                                                content={todo.task}
                                                className={todo.done ? 'task_done' : 'task'}
                                                onClick={() => setSelectedTodo(todos.find(item => (item.id = todo.id)))}
                                            ></ListDescription>
                                        </ListContent>
                                    </ListItem>
                                ))}
                        </List>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1} className="newTaskBtn">
                    <Grid.Column>
                        <Button primary type="button" onClick={() => setSelectedTodo(initialValues)}>
                            Uusi tehtävä
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Segment>
            {selectedTodo && (
                <Formik initialValues={selectedTodo} enableReinitialize onSubmit={storeTodo}>
                    {({ isSubmitting, errors }) => (
                        <Form>
                            <Segment>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <FieldText name="task" title="Tehtävä" required />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <FieldText name="person" title="Vastuu" required />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <FieldCheckbox name="doing" label="Työnalla" />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <FieldCheckbox name="done" label="Valmis" />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <Button
                                            primary
                                            type="submit"
                                            disabled={isSubmitting || Object.keys(errors).length > 0}
                                        >
                                            Tallenna
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Segment>

                            {showMessage.title.length > 0 && (
                                <Message style={{ zIndex: 1000 }} color={showMessage.success ? 'green' : 'red'}>
                                    <Message.Header>{showMessage.title}</Message.Header>
                                </Message>
                            )}
                        </Form>
                    )}
                </Formik>
            )}
        </Container>
    );
};

export default TodoView;
