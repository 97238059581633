import React from 'react';
import logo from 'assets/logo.svg';
import CountDownClock from './CountDownClock';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Image, Header } from 'semantic-ui-react';
import config from 'config/config';

const AppHeader: React.FC = () => {
    const navigate = useNavigate();
    return (
        <header
            className="app-header"
            onClick={() => {
                navigate('/');
            }}
        >
            <Container>
                <Grid centered columns={5} mobile={2}>
                    <Grid.Row only="computer">
                        <Grid.Column verticalAlign="middle">
                            <Image src={logo} alt="logo" className="logo" />
                        </Grid.Column>
                        <Grid.Column verticalAlign="middle">
                            <Header className="page-title">AsLAN</Header>
                        </Grid.Column>
                        <Grid.Column verticalAlign="middle" only="computer">
                            <CountDownClock disabled={config.REGISTRATION_OPEN !== 'true'} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row only="mobile">
                        <Grid.Column width={6} verticalAlign="middle">
                            <Image src={logo} alt="logo" style={{ minWidth: 90 }} />
                        </Grid.Column>
                        <Grid.Column width={8} verticalAlign="middle">
                            <Header className="page-title">AsLAN</Header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </header>
    );
};

export default AppHeader;
