import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Header } from 'semantic-ui-react';

const IntraView: React.FC = () => {
    return (
        <Container text>
            <Header as="h2">AsLAN Intra</Header>
            TODO
            <fieldset>
                <legend>Sisällys</legend>
                <ul>
                    <li>
                        <a href="#t">...</a>
                    </li>
                </ul>
            </fieldset>
            <Header as="h3" id="t">
                ...
            </Header>
            <p style={{ color: 'whitesmoke' }}>not yet here...</p>
            <Link to={'/todo'} title="Todo">
                Todo
            </Link>
        </Container>
    );
};

export default IntraView;
