import React, { FC } from 'react';
import { Grid } from 'semantic-ui-react';
import Subtitle from 'components/Subtitle';
import FieldText from './FieldText';

const GuardianInfo: FC = () => {
    return (
        <>
            <Subtitle title="Huoltajan tiedot" />
            <p>Jos olet alle 15-vuotias, tarvitsemme huoltajan yhteystiedot. Pyydäthän luvan tulla mukaan.</p>
            <Grid columns={1} stretched stackable>
                <Grid.Column>
                    <FieldText name="huoltajaNimi" title="Huoltajan nimi" />
                </Grid.Column>
                <Grid.Column>
                    <FieldText name="huoltajaPuhelin" title="Huoltajan puhelinnumero" />
                </Grid.Column>
            </Grid>
        </>
    );
};

export default GuardianInfo;
