import React, { FC } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import Subtitle from 'components/Subtitle';
import FieldText from './FieldText';
import FieldCheckbox from './FieldCheckbox';
import { useFormikContext } from 'formik';
import { RegistrationDto } from 'types/common';

const TableReservation: FC = () => {
    const { values } = useFormikContext<RegistrationDto>();

    return (
        <>
            <Subtitle title="Pöytäpaikka varaus" />
            <p>
                Pöydän koko on 85 x 75 cm ja vieressä on pehmustettu tuoli! Yksi pistorasia ja yksi nettipistoke per
                pöytä. Tuo oma jatkojohto ja oma nettipiuha (varajohtoja löytyy kyllä).
            </p>
            <Header as="h4">
                Tuon mukana <span className="required">*</span>
            </Header>
            <Grid columns={2} stretched stackable>
                <Grid.Column width="16">
                    <FieldCheckbox name="mukaanPc" label="pc pelikone/läppäri (+näyttö)" />
                </Grid.Column>
                <Grid.Column width="16">
                    <FieldCheckbox name="mukaanKonsoli" label="konsoli (+näyttö)" />
                </Grid.Column>
                <Grid.Column width="16">
                    <FieldCheckbox name="mukaanHengailu" label="tulen vain hengailemaan" />
                </Grid.Column>
                <Grid.Column width="2">
                    <FieldCheckbox name="mukaanMuuta" label="Muuta: " />
                </Grid.Column>
                <Grid.Column width="14">
                    <FieldText
                        name="mukaanMuutaKommentti"
                        title=""
                        placeholder="Muuta"
                        size="mini"
                        style={{ width: 250 }}
                        disabled={!values.mukaanMuuta}
                    />
                </Grid.Column>
            </Grid>
        </>
    );
};

export default TableReservation;
