import React, { useCallback, useState } from 'react';
import { Button, Container, Header, Message, Rating, Segment } from 'semantic-ui-react';
import { FeedbackDto } from 'types/common';
import feedbackService from 'services/feedbackService';
import { Form, Formik } from 'formik';
import FieldText from './registration/FieldText';

const FeedbackView: React.FC = () => {
    const [showMessage, setShowMessage] = useState({ title: '', success: false });
    const [sent, setSent] = useState(false);
    const initialValues: FeedbackDto = {
        overall: 0,
        organizing: 0,
        food: 0,
        tournaments: 0,
        atmosphere: 0,
        comments: ''
    };

    const storeFeedback = useCallback(async (values: FeedbackDto) => {
        setSent(true);
        try {
            const res = await feedbackService.feedback(values);
            if (res.success) {
                setShowMessage({
                    title: 'Kiitos palautteestasi!',
                    success: true
                });
            } else {
                setShowMessage({
                    title: 'Palautteen tallennus epäonnistui!',
                    success: false
                });
            }
        } catch (err: any) {
            setShowMessage({
                title: 'Palautteen tallennus epäonnistui!',
                success: false
            });
        }
    }, []);

    return (
        <Formik initialValues={initialValues} onSubmit={storeFeedback}>
            {({ isSubmitting, errors, setFieldValue }) => (
                <Form>
                    <Container text>
                        <Header as="h2">AsLAN Palaute</Header>

                        <Segment>
                            <Header size="tiny">Yleisfiilis</Header>
                            <Rating
                                icon="star"
                                size="huge"
                                maxRating={5}
                                onRate={(_, data) => {
                                    setFieldValue('overall', data.rating);
                                }}
                            />
                        </Segment>

                        <Segment>
                            <Header size="tiny">Järjestelyjen toimivuus</Header>
                            <Rating
                                icon="star"
                                maxRating={5}
                                onRate={(_, data) => {
                                    setFieldValue('organizing', data.rating);
                                }}
                            />
                        </Segment>

                        <Segment>
                            <Header size="tiny">Ruoka</Header>
                            <Rating
                                icon="star"
                                maxRating={5}
                                onRate={(_, data) => {
                                    setFieldValue('food', data.rating);
                                }}
                            />
                        </Segment>

                        <Segment>
                            <Header size="tiny">Turnaukset</Header>
                            <Rating
                                icon="star"
                                maxRating={5}
                                onRate={(_, data) => {
                                    setFieldValue('tournaments', data.rating);
                                }}
                            />
                        </Segment>

                        <Segment>
                            <Header size="tiny">Ilmapiiri</Header>
                            <Rating
                                icon="star"
                                maxRating={5}
                                onRate={(_, data) => {
                                    setFieldValue('atmosphere', data.rating);
                                }}
                            />
                        </Segment>

                        <Segment>
                            <FieldText name="comments" title={'Vapaa kommentointi'} size="small" />
                        </Segment>

                        <Button primary type="submit" disabled={isSubmitting || sent || Object.keys(errors).length > 0}>
                            Tallenna
                        </Button>
                        {showMessage.title.length > 0 && (
                            <Message style={{ zIndex: 1000 }} color={showMessage.success ? 'green' : 'red'}>
                                <Message.Header>{showMessage.title}</Message.Header>
                            </Message>
                        )}
                    </Container>
                </Form>
            )}
        </Formik>
    );
};

export default FeedbackView;
